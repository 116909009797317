<template>
    <TabView>
        <TabPanel v-for="capp in categories" :key="capp.id" :header="capp.name">
            <PegasusPanel v-for="project in capp.itemGroups" :key="project.id" :header="project.name">
                <div
                    v-for="item in getNonZeroItems(project)"
                    :key="item.id"
                    class="panel card card-success item"
                    @click="emit('selected', item)"
                >
                    <div class="card-header with-border collapsed">
                        <p class="card-title" style="font-size: 14px;">
                            #{{ item.id }} {{ item.name }}
                        </p>

                        <span style="margin-left: 30px;">
                            {{ item.decidedPrice.huf() }}
                        </span>
                    </div>
                </div>

                <span v-if="isEmpty(getNonZeroItems(project)) && isEmpty(project.allChildren)">
                    Nincs elfogadott tétel
                </span>

                <div v-for="group in project.allChildren" :key="group.id">
                    <div
                        v-for="item in getNonZeroItems(group)"
                        :key="item.id"
                        class="panel card card-success item"
                        @click="emit('selected', item)"
                    >
                        <div class="card-header with-border collapsed">
                            <p class="card-title" style="font-size: 14px;">
                                #{{ item.id }} {{ item.name }}
                            </p>

                            <span style="margin-left: 30px;">
                                {{ item.decidedPrice.huf() }}
                            </span>
                        </div>
                    </div>
                </div>
            </PegasusPanel>
        </TabPanel>
    </TabView>

    <span v-if="isEmpty(application.categories)">Nincsen költési időszakban lévő KPR pályázat</span>
</template>

<script lang="ts" setup>
import {ItemGroup} from "@Models/money/ItemGroup";
import {filter, isEmpty, map, sortBy} from "lodash-es";
import type {DecidedItem} from "@Models/money/Item";
import {Item} from "@Models/money/Item";
import PegasusPanel from "@Components/base/PegasusPanel.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import {Application} from "@Models/rvt/kpr/Application";
import {computed} from "vue";

const {application, onlyApproved, onlyAmendable} = defineProps<{
    application: Application,
    onlyApproved: boolean,
    onlyAmendable: boolean
}>();

const emit = defineEmits<{
    selected: [item: Item]
}>();

const categories = computed(() => {
    let result: {id: string, name: string, itemGroups: Record<number, ItemGroup>}[] = [];

    for (const capp of sortBy(application.categories, capp => capp.category.id)) {
        result.push({
            id: capp.id.toString(),
            name: capp.category.name,
            itemGroups: capp.itemGroup.alternativeChildren,
        });
    }

    const amends = filter(application.amends, amend => amend.decision === true);

    if (!isEmpty(amends)) {
        result.push({
            id: 'amends',
            name: 'KPR módosítók',
            itemGroups: map(amends, amend => amend.itemGroup),
        });
    }

    return result;
});

function getNonZeroItems(project: ItemGroup): DecidedItem[] {
    return filter(project.items, item => {
        if (onlyApproved && (item.decidedPrice === null || item.decidedPrice === 0)) {
            return false;
        }

        if (onlyAmendable && item.amended) {
            return false;
        }

        return true;
    }) as DecidedItem[];
}
</script>
